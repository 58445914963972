<template>
  
    <div v-if="banner.length > 0">
        <div id="carouselExampleControlsNoTouching" class="carousel slide" data-bs-ride="carousel">
            <div class="carousel-inner">
                <template v-for="data in banner" :key="data.id">
                    <div class="carousel-item active" data-bs-interval="10000">
                        <img :src="bannerPath + data.carousel" class="d-block w-100" :alt="data.carousel">
                    </div>
                </template>
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControlsNoTouching" data-bs-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControlsNoTouching" data-bs-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Next</span>
            </button>
        </div>
    </div>

    <div v-else>
        <section class="banner banner-full">
            <img class="bg-banner" src="../../assets/banner/404.png" alt="Banner">
        </section>

        <!-- banner tag -->
        <section class="banner-tag">
            <span class="text-right text-white"> {{ caption }} </span>
        </section>
    </div>

    <section class="content-home border-bottom-red">
        <div class="container-fluid">
            <div class="row">
                <div class="col-2">
                    <div class="content-block">
                        <img class="icon-red img-left" src="../../assets/images/sekilas-tentang-w.svg" alt="icon">
                    </div>
                </div>

                <div class="col-10 mb-5">
                    <div class="content-block">
                        <h3 class="mb-3">Detail <span class="alternate">Armada</span></h3>

                        <div v-if="armada.length > 0">
                            <template v-for="data in armada" :key="data.id">
                                <h5 class="text-red"> {{data.carousel}} </h5>
                                <p v-html="data.deskripsi"></p>
                            </template>
                        </div>

                        <div v-else>
                            <div class="text-center">
                                <h4 class="text-red">Halaman Tidak Tersedia.</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

</template>

<script>

import axios from 'axios'

export default {
    name: 'detailArmada',
    props: [
        'id'
    ],
    data () {
        return {
            // bannerPath: 'http://localhost:8080/assets/carousel/',
            bannerPath: 'https://api.plnbag.co.id/assets/carousel/',
            banner: [],
            caption: '',
            armada: []
        }
    },
    mounted () {
        this.loadBanner()
        this.loadArmada()
    },
    methods: {
        loadBanner () {
            axios.get('armada_carousel?id_armada=' + this.id)
                .then((res) => {
                    this.banner = res.data.data
                })
        },

        loadArmada () {
            axios.get('armada_detail?id_armada=' + this.id)
                .then((res) => {
                    this.armada = res.data.data
                })
        }
    }
}
</script>

<style scoped>
    @import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700|Montserrat:300,400,500,700");

    h1, h2, h3, h4, h5, h6 {
        font-family: "Montserrat", sans-serif;
        color: #222222;
        font-weight: 600;
    }

    h1 {
        font-size: 2.8rem;
    }

    h2 {
        font-size: 2.5rem;
    }

    h3 {
        font-size: 2.25rem;
    }

    h4 {
        font-size: 1.6875rem;
    }

    h5 {
        font-size: 1.375rem;
    }

    h6 {
        font-size: 1.25rem;
    }

    p, a, li, blockquote, label {
        font-size: 1rem;
        line-height: 26px;
        color: #848484;
        margin-bottom: 0;
    }

    .bg-banner {
        width: 100%;
        height: 100%;
        text-align:center;
        object-position:center;
    }

    .banner {
        /*padding: 250px 0 150px;*/
        /*padding: 300px;*/
        position: relative;
        margin-top: 120px;
    }

    /*@media (max-width: 1024px) {
        .banner {
            padding: 100px 0;
        }
    }*/

    .banner-full .bg-banner {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -moz-flex;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }

    .banner-full .bg-banner {
        height: auto;
        margin-top :30px;
    }

    @media (max-width: 767px) {
        .banner-full .bg-banner {
            margin-bottom: 0px;
            height: auto;
            margin-top: 0px;
        }
    }

    .banner-tag {
        background: #0158a7;
        padding-bottom: 30px;
        border-bottom: 5px solid #ea2e23;
    }

    .banner-tag span {
        float: right;
        text-transform: uppercase;
        font-weight: 200;
        color: #fff;
        padding-right: 35px;
        padding-top: 3px;
    }

    .content-home {
        position: relative;
    }

    .content-home .content-block {
        padding: 25px 0 0;
    }

    .content-home .content-block h3 {
        color: #0158a7;
        text-transform: uppercase;
        font-weight: 600;
        margin-bottom: 15px;
        font-size: 17px;
    }

    .content-home .content-block span.title-w {
        color: #fff;
        text-transform: uppercase;
        font-weight: 600;
        margin-left: 10px;
        margin-bottom: 15px;
        font-size: 17px;
    }

    .content-home .content-block p.text-w {
        color: #fff;
        margin-bottom: 20px;
        font-weight: 200;
        text-align: justify;
    }

    .content-home .content-block p {
        color: #404041;
        margin-bottom: 20px;
        font-weight: 200;
        text-align: justify;
    }

    .content-home .image-block {
        position: absolute;
        right: 5%;
        bottom: 0;
    }

    @media (max-width: 767px) {
        .content-home .image-block {
            display: none;
        }
    }

    .border-bottom-red {
        border-bottom: 5px solid #ea2e23;
    }

    .icon-red {
        background-color: #ea2e23;
        border-radius: 5px;
        padding: 10px;
        float: right;
    }

    @media (max-width: 767px) {
        .icon-red {
            float: none;
        }
    }

    .img-left {
        width: 85px;
        height: auto;
    }

    @media screen and (max-width: 767px) {
        .img-left {
            width: 45px;
            height: auto;
        }
    }

    span.alternate {
        color: #ea2e23;
        font-weight: 800;
    }

    .text-red {
        color: #ea2e23;
    }

</style>